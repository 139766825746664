import { useTranslation } from "next-i18next";
import { useAuthSelector, useIsLoggedIn } from "@nf/common";
import { useIsLoading } from "helpers/setIsLoadingContext";
import { SignInButton } from "./SignInButton";
import { useSignUpUrl } from "hooks";
import React, { memo, useEffect, useState } from "react";
import { fromEvent, Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import cx from "classnames";
import styles from "./AppHeaderSignUpMobile.module.scss";
import { LogInButtonMobile } from "./LogInButtonMobile";
const EVENT_NAME = "scroll";

export type TAppHeaderSignUpMobile = {
  isLandingPage?: boolean;
  fixedPosition?: boolean;
};

export const AppHeaderSignUpMobile: React.FC<TAppHeaderSignUpMobile> = memo(
  ({ isLandingPage, fixedPosition = false }) => {
    const [withBackground, setWithBackground] = useState(false);
    const { t } = useTranslation("translations");
    const isLoggedIn = useIsLoggedIn();
    const { isLoading: isUserLoading } = useAuthSelector();
    const { isLoading } = useIsLoading();
    const signUpUrl = useSignUpUrl();

    useEffect(() => {
      let subscription: Subscription | undefined;
      if (isLandingPage) {
        subscription = fromEvent(document, EVENT_NAME)
          .pipe(
            map(() => {
              return window.scrollY;
            }),
            map((scrollY) => scrollY > 2),
            distinctUntilChanged()
          )
          .subscribe((addBackground) => {
            setWithBackground(addBackground);
          });
      }

      return () => {
        subscription?.unsubscribe();
      };
    }, [isLandingPage]);

    if (isLoggedIn || isLoading || isUserLoading) {
      return null;
    }

    return (
      <div
        className={cx(styles.buttonContainer, {
          [styles.menuBackground]: withBackground,
          [styles.fixedPosition]: fixedPosition,
        })}
      >
        <SignInButton
          label={t("web_create_account_link", "")}
          url={signUpUrl}
          variant="primary"
        />
        <LogInButtonMobile />
      </div>
    );
  }
);

export default AppHeaderSignUpMobile;
