import dynamic from "next/dynamic";
import React, { FC } from "react";

const LinkWithLocale = dynamic(() => import("components/LinkWithLocale"));
const MediaButton = dynamic(() => import("components/MediaButton"));

import styles from "./AppHeaderSignUpMobile.module.scss";

type TSigInButton = {
  label: string;
  url: string;
  variant?: "plain" | "primary" | "secondary";
};

export const SignInButton: FC<TSigInButton> = ({
  label,
  url,
  variant = "plain",
}) => {
  return (
    <LinkWithLocale href={url}>
      <a>
        <MediaButton
          variant={variant}
          tabIndex={-1}
          className={styles.signInButton}
        >
          {label}
        </MediaButton>
      </a>
    </LinkWithLocale>
  );
};
